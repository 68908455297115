.svg-icon {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);

  &--white {
    fill: #fff;
  }

  &--blue {
    fill: #4264fd;
  }

  &--indent {
    margin-right: 5px;
  }
}
