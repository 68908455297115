$header-top-height: 55px;
$header-top-height-vw: get-vw($header-top-height);

.header {
  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;

    ~ .fallback-view,
    ~ .placeholder-st {
      margin-top: get-vw(55px);
    }
  }

  &-top {
    position: relative;
    z-index: 11;
    padding: get-vw(7px) get-vw(10px);
    padding-left: 0;
    background-color: #946952;
    box-sizing: border-box;
    height: $header-top-height-vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mq(480) {
      padding: 7px 10px;
      padding-left: 0;
      height: $header-top-height;
    }
  }

  &-logo {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center top get-vw(10px);
    background-size: 31.25vw 10.625vw;
    width: 31.25vw;
    font-size: 0;
    color: transparent;
    user-select: none;
    height: $header-top-height-vw;

    .locale-ru & {
      background-image: url($base64-logo-ru);
    }

    .locale-az & {
      background-image: url($base64-logo-az);
    }

    @include mq(480) {
      background-position: center top 10px;
      height: $header-top-height;

      .locale-az & {
        background-size: 100px 34px;
        width: 100px;
      }

      .locale-ru & {
        background-size: 101px 32px;
        width: 101px;
      }
    }
  }

  &-new-btn {
    background-image: url($base64-circle-plus);
    background-repeat: no-repeat;
    background-position: get-vw(14px) center;
    background-size: get-vw(15px);
    background-color: #69bb10;
    padding-left: get-vw(34px);
    padding-right: get-vw(14px);
    box-shadow: 0 1px get-vw(4px) 0 rgba(97, 97, 97, 0.58);
    border-radius: get-vw(2px);
    margin-left: auto;
    font-size: get-vw(14px);
    line-height: get-vw(35px);
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    font-family: $helvetica;

    @include mq(480) {
      background-repeat: no-repeat;
      background-position: 14px center;
      background-size: 15px;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      font-size: 14px;
      line-height: 35px;
      padding-left: 34px;
      padding-right: 14px;
      box-shadow: 0 1px 4px 0 rgba(97, 97, 97, 0.58);
      border-radius: 2px;
    }
  }
}

.open-drawer {
  background-image: url($base64-bars);
  background-position: get-vw(10px) center;
  background-size: get-vw(22px) get-vw(21px);
  background-repeat: no-repeat;
  height: get-vw(55px);
  width: get-vw(42px);

  &:active {
    opacity: 0.4;
  }

  @include mq(480) {
    background-position: 15px center;
    background-size: 22px 21px;
    height: 55px;
    width: 47px;
  }
}
