.modal {
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100.1%; // fix bug with url bar in Chrome Mobile
    overflow-y: scroll;
    background-color: $colour-white;

    &--map {
      position: relative;
      height: auto;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    font-family: $helvetica;
    background-color: $colour-white;
    // stylelint-disable-next-line
    -webkit-backface-visibility: hidden;

    &--map {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__portal {
    -webkit-overflow-scrolling: touch;
  }

  &__body--open {
    overflow: hidden;
  }
}
